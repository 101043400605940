import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Scrollspy from 'react-scrollspy';

const ScrollSpyWrapper = tw(Scrollspy)`mt-95 pl-0`;

const ScrollNavItem = styled(Text)`
  ${tw`cursor-pointer`}
  ${({ visibility }) => (visibility ? '' : tw`hidden`)}

  &.active { ${tw`font-600`} }
`;

const ScrollSpy = ({ stickyNav, collection }) => (
  <ScrollSpyWrapper items={stickyNav} currentClassName="active">
    {stickyNav.map((item, index) => (
      <ScrollNavItem
        key={item}
        visibility={index}
        onClick={() => scrollTo(`#${item}`)}
        fontSizes={16}
        lineHeight={3.13}
        letterSpacing={20}
        uppercase
      >
        <FormattedMessage id={`${collection}.nav.${item}`} />
      </ScrollNavItem>
    ))}
  </ScrollSpyWrapper>
);

ScrollSpy.propTypes = {
  stickyNav: PropTypes.arrayOf(PropTypes.string).isRequired,
  collection: PropTypes.string.isRequired,
};

export default ScrollSpy;
