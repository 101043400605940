import React from 'react';
import tw from 'twin.macro';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { navigation404 } from '@utils/navigation';

import Text from '@components/text';
import LinkDefault from '@components/link';

const Footer = tw.footer`flex flex-wrap justify-center items-center mt-25 -mx-20 mb-20 lg:-mx-25`;

const Link = tw(LinkDefault)`inline-block mx-20 lg:mx-25`;

const FooterAlternative = () => (
  <Footer>
    {navigation404.map(({ path, externalLink, translation }) => (
      <Link key={translation} to={path} isExternal={externalLink}>
        <Text fontSizes={16} lineHeight={2.19} letterSpacing={0}>
          <FormattedMessage id={translation} />
        </Text>
      </Link>
    ))}
  </Footer>
);

export default FooterAlternative;
