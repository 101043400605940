import React from 'react';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';

const Wrapper = tw.div`h-58 md:h-50`;

const Background = tw.div`fixed top-0 inset-x-0 bg-backgroundBlue z-50`;

const Container = styled.div`
  ${customContainer}
  ${tw`w-220 py-8 text-center md:w-auto md:py-15 lg:text-left`}
`;

const LangInfo = () => (
  <Wrapper>
    <Background>
      <Container>
        <Text fontSizes={[14, 16]} lineHeight={1.5} letterSpacing={20}>
          <FormattedMessage id="blog.mask" />
        </Text>
      </Container>
    </Background>
  </Wrapper>
);

export default LangInfo;
