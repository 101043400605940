import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { StickyContainer, Sticky } from 'react-sticky';
import ScrollSpy from '@components/sticky-nav/scroll-spy';

const StickyNavColumn = styled(StickyContainer)`
  left: calc(50% + (553px));
  ${tw`hidden absolute inset-y-0 w-170 z-1`}
  
  @media (min-width: 1440px) { ${tw`block`} }
`;

const StickyNav = ({ stickyNav, collection }) => (
  <StickyNavColumn>
    <Sticky>
      {({ style }) => (
        <div style={style}>
          <ScrollSpy stickyNav={stickyNav} collection={collection} />
        </div>
      )}
    </Sticky>
  </StickyNavColumn>
);

StickyNav.propTypes = {
  stickyNav: PropTypes.arrayOf(PropTypes.string).isRequired,
  collection: PropTypes.string.isRequired,
};

export default StickyNav;
