import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { breakpoints } from '@utils/variables';
import { ThemeProvider } from 'styled-components';
// eslint-disable-next-line no-unused-vars
import styles from '@components/base.css';

import LangInfo from '@components/blog/lang-info';
import Cookies from '@components/cookies';
import Header from '@components/header';
import StickyNav from '@components/sticky-nav';
import Footer from '@components/footer';
import Copyrights from '@components/copyrights';
import FooterAlternative from '@components/footer/alternative';

const Wrapper = tw.div`overflow-x-hidden`;

const Main = styled.main`
  ${({ relative }) => (relative ? tw`relative` : tw`static`)}
`;

const Layout = ({
  children, stickyNav, collection, alternativeFooter, switchPath, langInfo,
}) => (
  <ThemeProvider theme={{ breakpoints }}>
    <Wrapper>
      {langInfo && <LangInfo />}
      <Cookies />
      <Header switchPath={switchPath} />
      <Main relative={stickyNav}>
        {stickyNav && <StickyNav stickyNav={stickyNav} collection={collection} />}
        {children}
      </Main>
      {alternativeFooter ? <FooterAlternative /> : (
        <>
          <Footer />
          <Copyrights />
        </>
      )}
    </Wrapper>
  </ThemeProvider>
);

Layout.defaultProps = {
  stickyNav: false,
  collection: 'coffee',
  alternativeFooter: false,
  switchPath: [],
  langInfo: false,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  stickyNav: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.bool,
  ]),
  collection: PropTypes.string,
  alternativeFooter: PropTypes.bool,
  switchPath: PropTypes.arrayOf(PropTypes.string),
  langInfo: PropTypes.bool,
};

export default Layout;
